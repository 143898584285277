<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>

      <v-card-text>
        <b>ATTENTION:</b> Votre compte de paiement Stripe est bloqué et demande votre attention pour que vous puissiez recevoir
        à nouveau vos paiements des commandes.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="closePopup" > Plus tard </v-btn>
        <v-btn color="primary" text @click="navigate()" > J'y vais </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import BILLING_ACCOUNTS from "@/graphql/billing/billing-accounts.gql";

  export default {
    data () {
      return {
        dialog: false,
        interval: 0,
        mustAlert: false,
        billingAccounts: []
      }
    },
    methods: {
      closePopup () {
        this.dialog = false;
        clearInterval(this.interval);
      },
      navigate() {
        this.dialog = false;
        clearInterval(this.interval);
        this.$router.push('/billing-account')
      }
    },
    mounted () {
      if (!this.$auth.ensure('ALERT_STRIPE_ACCOUNT')) {
        return;
      }
      this.$apollo.query({
        query: BILLING_ACCOUNTS
      }).then(res => {
        this.billingAccounts = res.data.billingAccounts;
      })
      this.interval = setInterval(function () {
        this.mustAlert = this.billingAccounts.find(acct =>
            acct.stripeAccount && (acct.stripeAccount.capabilities.transfers !== 'active'
            || (acct.stripeAccount.requirements.errors && acct.stripeAccount.requirements.errors.length)));

        if (this.mustAlert) {
          this.dialog = true;
        }
      }.bind(this), 6000)

    }
  }
</script>